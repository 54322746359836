<template>
  <div class="content">
    <p class="title">《使用条款》</p>
    <p>
    面粉坊提醒您：在使用本站点各项服务前，请务必仔细阅读并理解本协议
    </p><p>
    一、总则
  </p><p>
    1.1 同意遵守本协议及所有业务规则后方可成为面粉坊用户。一旦注册成功，您与本平台之间自动形成协议关系，需受本协议及所有业务规则的约束。
  </p><p>
    1.2 本协议根据面粉坊运营情况随时更新，面粉坊不承担通知义务，请您在使用时密切关注。
  </p><p>
    二、帐户
  </p><p>
    2.1 您注册的帐户如果出现以下情况，面粉坊将有权禁止您在网站的一切活动。
  </p><p>
    以党和国家领导人的真实姓名或音近名称注册；
  </p><p>
    以国家机构或者其他机构的名称注册；
  </p><p>
    以残疾人服务、残疾人信息等相关或相近名称注册；
  </p><p>
    以含有恶意人身攻击或淫秽字眼的名称注册；
  </p><p>
    以机器手段批量注册。
  </p><p>
    2.2 您有义务保证个人帐号和密码的安全，因保管不当引起的任何损失或损害，面粉坊不承担任何责任。
  </p><p>
    三．使用
  </p><p>
    3.1 欢迎您随时使用面粉坊参与业务活动，但如果您在本软件平台上发布、转载、传送含有任何下列内容的信息，本平台将有权自行处理：
  </p><p>
    （1）煽动抗拒、破坏宪法和法律、行政法规实施的；
  </p><p>
    （2）煽动颠覆国家政权，推翻社会主义制度的；
  </p><p>
    （3）煽动分裂国家、破坏国家统一的；
  </p><p>
    （4）煽动民族仇恨、民族歧视，破坏民族团结的；
  </p><p>
    （5）捏造或者歪曲事实，散布谣言，扰乱社会秩序的；
  </p><p>
    （6）宣扬封建迷信、淫秽、色情、赌博、暴力、凶杀、恐怖、教唆犯罪的；
  </p><p>
    （7）公然侮辱他人或者捏造事实诽谤他人的，或者进行其他恶意攻击的；
  </p><p>
    （8）损害国家机关信誉的；
  </p><p>
    （9）其他违反宪法和法律行政法规的；
  </p><p>
    （10）在未经过面粉坊授权的情况下进行商业广告行为的；
  </p><p>
    （11）含有法律、行政法规禁止的其他内容的。
  </p><p>
    四、隐私
  </p><p>
    4.1 为了向您提供更好的用户服务，请您在注册时及时、详尽、准确地提供个人资料，并不断更新补充。如果因注册信息不真实出现任何问题，由您自行承担相应的后果。
  </p><p>
    4.2 面粉坊非常重视对您个人隐私的保护，承诺不对外公开或向第三方提供用户的注册信息和在本网站的相关使用情况。但以下几种特殊情况除外：
  </p><p>
    （1）得到了您的授权许可；
  </p><p>
    （2）只有透露您的个人资料，才能提供您所要求的产品和服务；
  </p><p>
    （3）根据有关的法律法规要求；
  </p><p>
    （4）按照相关政府主管部门的要求；
  </p><p>
    （5）为维护面粉坊的合法权益。
  </p><p>
    （6）为维护公共安全。
  </p><p>
    五、版权
  </p><p>
    5.1 面粉坊所有文字、图片、音频、视频等版权均归本平台享有或本平台与作者共同享有，未经本网站许可，不得任意转载。
  </p><p>
    5.2 面粉坊LOGO标识、吉祥物、所有网站产品设计及编排方式等版权均属本网站享有，未经本网站许可，不得任意复制转载。
  </p><p>
    5.3 您在面粉坊发布的任何原创内容，可以自行在发布内容中标注“原创”或“未经同意不得转载”等说明，如您在面粉坊发布的任何原创内容，出现被他人私自转载等情况，面粉坊不承担责任。
  </p><p>
    5.4 您须承诺在面粉坊发表的所有信息均享有合法版权或已经得到版权拥有人的授权，不得侵害他人合法权利。一旦权利人提出异议并提供书面通知和有效的证明资料后，面粉坊有权立即不予审核通过或删除该信息。如因违反本条规定造成实质侵权的，将由用户个人承担相关法律责任和赔偿等。
  </p><p>
    5.5 本网站所有内容仅代表作者自己的立场和观点，并不代表面粉坊立场。
  </p><p>
    5.6 恶意转载本网站内容的，面粉坊将保留对其提出法律诉讼的权利。
  </p><p>
    5.7 本协议的版权为公司所有，公司保留一切解释和修改的权利。
  </p><p>
    六、免责
  </p><p>
    除上述内容所涉及条款外，出现以下情况时面粉坊将不承担任何责任：
  </p><p>
    6.1 因不可抗力或面粉坊不能控制的原因（含系统升级和维护）而造成的网络服务中断、数据丢失或其他缺陷。但面粉坊承诺将竭尽所能减少因此而给您带来的损失和影响。
  </p><p>
    6.2 您在面粉坊发布对商家、单位及其他个人等的投诉信息，并由此产生争议和纠纷的。
  </p><p>
    6.3 您在面粉坊发布个人或他人真实信息，并由此产生纠纷和伤害的。
  </p><p>
    6.4 您使用出现面粉坊的外部链接、QQ群号、QQ、微信群、微信号、MSN等信息，参加其他用户个人组织的活动或与其他用户进行个人交易并由此发生纠纷和其他伤害的。
  </p><p>
    6.5 您使用出现在面粉坊中第三方输入的，不是本网发布的材料，并由此产生争议、纠纷和其他伤害的。
  </p><p>
    6.6 您在面粉坊发布的除前述范围以外的其它信息，并由此产生纠纷和伤害的。
  </p><p>
    七、附则
  </p><p>
    7.1 本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。
  </p><p>
    7.2 面粉坊的所有权和运营权归鲁山县焕值面粉厂所有。
  </p><p>
    7.3 本协议解释权及修订权归鲁山县焕值面粉厂所有。
  </p>
    
  </div>
</template>

<script>
export default {
  name: "user"
}
</script>

<style lang="scss">
.content {
  margin: 1.5rem;
}

.content .title{
  text-align: center;
}
.content p{
  text-indent:2em
}
</style>